
export enum DemoAppActions {
  INIT_REQUEST = "@@demoApp/INIT_REQUEST",
  INIT_SUCCESS = "@@demoApp/INIT_SUCCESS",
  INIT_ERROR = "@@demoApp/INIT_ERROR",

  LOAD_STAGE_REQUEST = "@@demoApp/LOAD_STAGE_REQUEST",
  LOAD_STAGE_SUCCESS = "@@demoApp/LOAD_STAGE_SUCCESS",
  LOAD_STAGE_ERROR = "@@demoApp/LOAD_STAGE_ERROR",

  QUEUE_CUTSCENE = "@@demoApp/QUEUE_CUTSCENE",
  DEQUEUE_CUTSCENE = "@@demoApp/DEQUEUE_CUTSCENE",

  QUEUE_DAILYQUEST = "@@demoApp/QUEUE_DAILYQUEST",
  DEQUEUE_DAILYQUEST = "@@demoApp/DEQUEUE_DAILYQUEST",

  QUEUE_ACTIVEQUEST = "@@demoApp/QUEUE_ACTIVEQUEST",
  DEQUEUE_ACTIVEQUEST = "@@demoApp/DEQUEUE_ACTIVEQUEST"
}
